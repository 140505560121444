//contant error messages for the app
import React from 'react';

export const ERROR_GENERIC = React.createElement('div', null, 'An error have occured. Please try again later.');

export const ERROR__TIMELINE_NOT_FOUND = React.createElement(
  'div',
  null,
  'An error have occured, the timeline is not found. Please try again later.',
);

export const ERROR__EVENTS_NOT_FOUND = React.createElement(
  'div',
  null,
  'An error have occured, the events are not found. Please try again later.',
);
