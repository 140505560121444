import { event } from 'react-ga';
import { Event_Interface } from '../../../interfaces/Event.interface';

export const left = function (
  containerWidth: number,
  containerPadding: number,
  eventCardWidth: number,
  includeCard: boolean,
  position: number,
): number {
  let leftInPx = 0;
  if (containerWidth > 1132) {
    leftInPx = includeCard
      ? 30 + position * (containerWidth - containerPadding * 2 - eventCardWidth - 40)
      : 30 + position * (containerWidth - containerPadding * 2 - 40);
  } else {
    if (containerWidth > 900) {
      leftInPx = includeCard
        ? 30 + position * (containerWidth - containerPadding * 2 - eventCardWidth - 40)
        : 30 + position * (containerWidth - containerPadding * 2 - 40);
    } else {
      if (containerWidth > 731) {
        leftInPx = includeCard
          ? 50 + position * (containerWidth - containerPadding * 2 - eventCardWidth - 60)
          : 50 + position * (containerWidth - containerPadding * 2 - 60);
      } else {
        if (containerWidth > 600) {
          leftInPx = includeCard
            ? 30 + position * (containerWidth - containerPadding * 1 - eventCardWidth - 80)
            : 30 + position * (containerWidth - containerPadding * 1 - 80);
        } else {
          if (containerWidth > 550) {
            leftInPx = includeCard
              ? 30 + position * (containerWidth - containerPadding * 1 - eventCardWidth - 80)
              : 30 + position * (containerWidth - containerPadding * 1 - 80);
          } else {
            if (containerWidth > 420) {
              leftInPx = includeCard
                ? 30 + position * (containerWidth - eventCardWidth - 20)
                : 30 + position * (containerWidth - 60);
            } else {
              if (containerWidth > 300) {
                leftInPx = includeCard
                  ? 30 + position * (containerWidth - eventCardWidth + 20)
                  : 30 + position * (containerWidth * 1 - 60);
              } else {
                leftInPx = includeCard
                  ? 30 + position * (containerWidth - eventCardWidth + 60)
                  : 30 + position * (containerWidth * 1 - 60);
              }
            }
          }
        }
      }
    }
  }

  return leftInPx > 0 ? leftInPx : 0;
};

function getDate(event: Event_Interface): Date {
  const date = new Date();
  date.setFullYear(Number(event.year));
  date.setMonth(event.month);
  date.setDate(event.day);
  return date;
}

export function initiateEvents(events: Event_Interface[]): { event: Event_Interface; date: Date; position: number }[] {
  let myEvents = events.map((event) => {
    const date = getDate(event);
    return {
      event: event,
      date: date,
      position: 0,
    };
  });
  myEvents = myEvents.sort((a, b) => a.date.getTime() - b.date.getTime());
  const minEvent = myEvents.reduce((a, b) => (a.date.getTime() < b.date.getTime() ? a : b));
  const maxEvent = myEvents.reduce((a, b) => (a.date.getTime() > b.date.getTime() ? a : b));
  myEvents = myEvents.map((event) => {
    return {
      event: event.event,
      date: event.date,
      position: (event.date.getTime() - minEvent.date.getTime()) / (maxEvent.date.getTime() - minEvent.date.getTime()),
    };
  });
  myEvents = adjustPosition(myEvents);
  return myEvents;
}

function adjustPosition(events: { event: Event_Interface; date: Date; position: number }[]) {
  const factor = 1; // between 0 and 1, 0 = no change, 1 = optimal, -> infiny = all with the same space

  if (events.length === 0) return events;
  const diffBetweenDots = [];
  for (let i = 0; i < events.length - 1; i++) {
    diffBetweenDots.push(events[i + 1].position - events[i].position);
  }
  const optimalDiff = 1 / events.length;
  let newDiff = diffBetweenDots.map((diff) => {
    return (diff + optimalDiff * factor) / (factor + 1);
  });
  const sum = newDiff.reduce((a, b) => a + b, 0);
  newDiff = newDiff.map((diff) => diff / sum);
  const newPosition = [0];
  for (let i = 0; i < newDiff.length; i++) {
    newPosition.push(newPosition[i] + newDiff[i]);
  }

  return events.map((event, key) => {
    return {
      event: event.event,
      date: event.date,
      position_init: event.position,
      position: newPosition[key],
    };
  });
}

export function initiateFictionEvents(
  events: Event_Interface[],
): { event: Event_Interface; date: Date; position: number }[] {
  let myEvents = events.map((event) => {
    return {
      event: event,
      date: new Date(Number(event.year), event.month, event.day),
      position: 0,
    };
  });

  myEvents = myEvents.sort((a, b) => (a.event.id > b.event.id ? 1 : -1));

  const minEvent = 0;
  const maxEvent = events.length;
  myEvents = myEvents.map((event, key) => {
    return {
      event: event.event,
      date: event.date,
      position: (key - minEvent) / (maxEvent - minEvent),
    };
  });
  return myEvents;
}

export function generateDateTranslate(event: Event_Interface): string {
  // Cas year && month && day
  if (event.year && event.month && event.day) return '-35';
  // Cas Year && month && !day
  else if (event.year && event.month && !event.day) return '-27';
  // Cas Year && !month && day
  else if (event.year && !event.month && event.day) return `-12`;
  // Cas Year && !month && !day
  else if (event.year && !event.month && !event.day) return `-15`;
  else return '-10';
}
