import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { SearchBar } from './SearchBar/SearchBar';
import styles from './NavBar.module.scss';
import { NavBarButton } from './NavBarButton';
import { SlideMenu } from '../SlideMenu/SlideMenu';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import UserIcon from '../../assets/img/User_Icon.png';
import { removeLoggedUserActionCreator } from '../../reducers/LoggedUser';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import wand from '../../assets/img/wand.png';
import wandGreen from '../../assets/img/wand_green.png';

export function NavBar(): JSX.Element {
  const loggedUser = useSelector((state: RootState) => state.loggedUser);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <div className={styles.navbar}>
        <NavLink to="/home" className={styles.navBarLogo}>
          <img
            className={`${styles.logo} hvr-grow`}
            src="https://res.cloudinary.com/dk2ghb1pg/image/upload/w_108,h_36,c_scale/v1531942668/Logo_eStory_simple_klalco.png"
            width="108"
            alt="logo"
          ></img>
        </NavLink>
        <NavBarButton to="home" />
        <NavBarButton to="explore" />
        <NavBarButton to="pricing" />
        <NavBarButton to="about" />
        <div className={styles.searchBarContainer}>
          <SearchBar />
        </div>
        <div className={styles.spacer} />
        {!!loggedUser ? (
          <div className={styles.userProfile}>
            <div
              className={styles.userName}
              onClick={() =>
                history.push(
                  `/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
                    ' ',
                    '-',
                  )}-${loggedUser?.lastname.replaceAll(' ', '-')}`,
                )
              }
            >
              <div className="col">
                <div className={styles.usernameToken}>
                  <div className={styles.fullName}>{loggedUser.firstname + ' ' + loggedUser.lastname + '\n'}</div>
                  <div className={styles.nbTokens}>
                    <a
                      //href="https://buy.stripe.com/test_9AQ2aMckYfS19WM005"
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      ✨ {loggedUser.nb_tokens} tokens
                    </a>
                  </div>
                </div>
              </div>
              <div style={{}}>
                {!!loggedUser.picture ? (
                  <img className={styles.profilePicture} src={loggedUser.picture} />
                ) : (
                  <img className={styles.profilePicture} src={UserIcon} />
                )}
              </div>
            </div>
            <div className={styles.dropdownContent}>
              <a
                href={`/user/${loggedUser?.id}/${loggedUser?.firstname.replaceAll(
                  ' ',
                  '-',
                )}-${loggedUser?.lastname.replaceAll(' ', '-')}`}
              >
                <p>My profile</p>
              </a>
              <a
                href="/"
                onClick={() => {
                  localStorage.removeItem('estory_user');
                  document.cookie = 'Authorization=; expires=' + new Date() + '; path=/;';
                  dispatch(removeLoggedUserActionCreator());
                }}
              >
                <p>Logout</p>
              </a>
            </div>
          </div>
        ) : (
          <NavBarButton to="login" />
        )}
        <div className={styles.createButton}>
          <Button variant="success" className={styles.createButtonNavLink}>
            <div className={styles.createButtonText}>Create Story</div>
            <div className={styles.createButtonTextShort}>Create</div>
          </Button>
          <div className={styles.dropdownContent2}>
            <a href="/create">
              <p>Historical timeline</p>
            </a>
            <a href="/createFiction">
              <div>
                <img className={styles.wandlogoGreen} src={wandGreen} />
                <img className={styles.wandlogo} src={wand} />
                Fiction
              </div>
            </a>
          </div>
        </div>
        <div className={styles.menu}>
          <SlideMenu nb_tokens={loggedUser?.nb_tokens != null ? loggedUser?.nb_tokens : 0} />
        </div>
      </div>
    </>
  );
}
